import React, { lazy } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import Loading from '@/components/molecules/Loading';
import { queryClient } from './config/queryClient';

const MainScreen = lazy(() => import('./pages/Main/index'));
/**
 * @returns {JSX.Element} The main Application
 */
function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <React.Suspense fallback={Loading}>
          <Routes>
            <Route path="/" element={<MainScreen />} />
            <Route path="/product/:slug" element={<MainScreen />} />
          </Routes>
        </React.Suspense>
      </Router>
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
