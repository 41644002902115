import toastNotify from 'react-hot-toast';

import { QueryClient, QueryCache } from 'react-query';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) =>
      toastNotify.error(
        `Something went wrong: ${error?.response?.data?.errors?.[0]?.message ?? error?.message}`,
      ),
  }),
});
